<template>
  <div>
    <CRow class="row-block">
      <CCol sm="4" lg="4" v-if="user != '8de886dd-12e5-4b02-b812-187639513779'">
       
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/yearlist'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox
              header="Academic Year"
              color="gradient-orange"
              v-bind:text="resp_data.ac_year"
            />
         </router-link> 
      </CCol>
      <CCol sm="4" lg="4">
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/classlist'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox header="Class" color="gradient-blue" v-bind:text="resp_data.class_cnt" />
         </router-link>
      </CCol>
      <CCol sm="4" lg="4">
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/studentlist'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox header="Student" color="gradient-violet" v-bind:text="resp_data.student"/>
         </router-link>
      </CCol>

      <CCol sm="4" lg="4">
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/subjectlist'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox header="Subject" color="gradient-red" v-bind:text="resp_data.subject"/>
         </router-link>
      </CCol>
      <CCol sm="4" lg="4" v-if="user != '8de886dd-12e5-4b02-b812-187639513779'">
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/teacherlist'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox header="Teacher" color="gradient-black" v-bind:text="resp_data.teacher" />
         </router-link>
      </CCol>
      <CCol sm="4" lg="4" v-if="user != '8de886dd-12e5-4b02-b812-187639513779'">
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/divisionlist'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox header="Division" color="gradient-green" v-bind:text="resp_data.division"/>
         </router-link>
      </CCol>
      <CCol sm="4" lg="4" v-if="user == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9' || user == '6b82d8f3-e879-42de-a8ff-2426b61c9ac9'">
         <router-link class="card-block stretched-link text-decoration-none"
          :to="{ path: '/dashboard/school'}" style="color:#1580ea;text-decoration:none;">
            <StatWidgetBox header="School" color="gradient-green" v-bind:text="resp_data.division"/>
         </router-link>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StatWidgetBox from "./StatWidgetBox";
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;
// import WidgetsDropdown from "./WidgetsDropdown";
// import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";

export default {
  name: "WidgetStats",
  data(){
    return{
      resp_data:[],
      class_data:"",
      user:""
    }
  },
  methods:{
    get_count_data(){
      var promise = apis.list_Home().then( response => {
          this.resp_data = response.data;
      }).catch( errror => {

      })
    }
  },
  components: {
    StatWidgetBox,
    // CChartLineSimple,
    // CChartBarSimple,
    // WidgetsBrand,
    // WidgetsDropdown,
  },
  created(){
    this.user = localStorage.getItem('user_role_id');
    this.get_count_data();
  }
};
</script>

<style scoped>
.row-block {
  flex-grow: 1;
}
</style>
