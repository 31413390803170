import { render, staticRenderFns } from "./StatWidgetBox.vue?vue&type=template&id=4f6416f8&scoped=true&"
import script from "./StatWidgetBox.vue?vue&type=script&lang=js&"
export * from "./StatWidgetBox.vue?vue&type=script&lang=js&"
import style0 from "./StatWidgetBox.vue?vue&type=style&index=0&id=4f6416f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6416f8",
  null
  
)

export default component.exports