<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CRow class="row-block mb-12"
        >
            <CCol sm="4" lg="4"  v-for="subject in subjects"
            :key="subject.subjectName">
                <a
                    class="card-block stretched-link text-decoration-none"
                    :href="'/#/details/'+subject.subjectName"
                >
                <StatWidgetBox v-bind:header="subject.subjectName" v-bind:colors="subject.subjectColor" color="gradient-green" v-bind:text="subject.totalUploads" v-bind:footer="subject.teacherName"/>
               </a>
               <!-- <CRow>
                   <CCol>
                       Teacher
                   </CCol>
               </CRow> -->
            </CCol>
        </CRow>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-book"/> Latest Content
            <div class="card-header-actions">
                <!-- <small class="text-muted">
                    <CButton color="primary" @click="add_contents">Add + </CButton>
                </small> -->
            </div>
          </CCardHeader>
          <CCardBody>
              <CCard>
                  <CCardHeader>
                      <CIcon name="cil-justify-center"/>
                      <strong> Tutorial - 2 </strong>
                      <div class="card-header-actions">
                      <a 
                          href="https://coreui.io/vue/docs/components/jumbotron" 
                          class="card-header-action" 
                          rel="noreferrer noopener" 
                          target="_blank"
                      >
                          <small class="text-muted">PDF</small>
                      </a>
                      </div>
                  </CCardHeader>
                  <CCardBody >
                      <CJumbotron style="padding:15px 10px; padding-left:30px;">
                      <h4>Maths - Chapter - 2</h4>
                      <p class="lead">Language - English</p>
                      <p>Class - 5</p>
                      <CButton  size = "sm" color="primary" variant="outline"><CIcon name="cil-file"/></CButton>&nbsp;
                      <CButton  size = "sm" color="info" variant="outline"><CIcon name="cil-cloud-download"/></CButton>
                      </CJumbotron>
                  </CCardBody>
              </CCard>
            </CCardBody>
        </CCard>
        <!-- <UserProfileCard /> -->
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StatWidgetBox from "../app-main-views/stats-widgets/StatWidgetBox.vue";
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";


export default {
  name: "Dashboard",
  data() {
      return{
          subjects: [
        {
          subjectName: "Marathi",
          teacherName: "Ms. Aruna Patil",
          totalUploads: 4,
          subjectColor: "warning"
        },
        {
          subjectName: "Hindi",
          teacherName: "Mr. Vinit Desai",
          totalUploads: 6,
          subjectColor: "success"
        },
        {
          subjectName: "English",
          teacherName: "Mr. B. V. Patil",
          totalUploads: 5,
          subjectColor: "primary"
        },
        {
          subjectName: "Social Science",
          teacherName: "Mr. Chandu Mali",
          totalUploads: 3,
          subjectColor: "secondary"
        },
        {
          subjectName: "Maths",
          teacherName: "Mr. Chandu Mali",
          totalUploads: 7,
          subjectColor: "danger"
        },
        {
          subjectName: "Science",
          teacherName: "Ms. Sangeeta Deshpande",
          totalUploads: 9,
          subjectColor: "info"
        },
        {
          subjectName: "Other Material",
          teacherName: "District Authorities",
          totalUploads: 2,
          subjectColor: "secondary"
        }
      ]
    
  }
  },
  components: {
    WidgetStats,
    UserProfileCard,
    CloudStorageChart,
    QuickNoteForm,
    StatWidgetBox
  },
  created(){
    let user_id = this.$session.get('user_id');
    let user_role_id = this.$session.get('user_role_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    }
    if(user_role_id != '8290f938-b20a-42e3-c201-08d8f3241655')
    {
      return '/';
    } 
          
  }
};
</script>
