<template>
  <div class="card card-profile text-center">
    <div
      class="top-block"
      :style="{ backgroundImage: `url(${profile.headerImg})` }"
    >
      <h4 class="py-3 pt-5">{{ profile.name }}</h4>
      <p class="px-5 py-3 about">
        {{ profile.about }}
      </p>
    </div>

    <div class="card-block">
      <img alt="" class="card-img-profile" v-bind:src="profile.profileImg" />
    </div>

    <div class="row pb-3 ">
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        <div class="row">
          <div class="col">
            <h5>Students</h5>
            <h5>{{ profile.followers }}</h5>
          </div>
          <div class="col">
            <h5>Contents</h5>
            <h5>{{ profile.following }}</h5>
          </div>
          <div class="col">
            <h5>Sharing Score</h5>
            <h5>{{ profile.notes }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        name: localStorage.getItem('user_name'),
        about:
          "1st - Higher Eductation Teacher , Mumbai",
        profileImg: "https://joeschmoe.io/api/v1/Aruna%20Patil",
        headerImg: "http://placeimg.com/640/480/any",
        followers: "9882",
        following: "1397",
        notes: "17200",
      },
    };
  },
  name: "UserProfileCard",
  components: {},
};
</script>

<style scoped>
.card-profile {
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 0;
  border: 0;
}
.card-profile .card-img-top {
  border-radius: 0;
}
.card-profile .card-img-profile {
  max-width: 150px;
  border-radius: 50%;
  margin-top: -95px;
  margin-bottom: 35px;
  border: 5px solid #e6e5e1;
}

.top-block {
  height: 300px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-block .about {
  font-size: 1.1em;
}
</style>
