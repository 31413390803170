<template>
  <CCard>
    <CCardHeader class="bg-dark text-white">
      Quick Notes
    </CCardHeader>
    <CCardBody class="bg-white">
      <form>
        <div class="form-group">
          <label for="noteTitle">Note Title</label>
          <input
            type="text"
            class="form-control"
            id="noteTitle"
            placeholder=""
          />
        </div>
        <div class="form-group">
          <label for="noteText">Your Text Here</label>
          <textarea class="form-control" id="noteText" rows="8"></textarea>
        </div>

        <button type="submit" class="btn btn-dark">Submit</button>
      </form>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "QuickNoteForm",
};
</script>
