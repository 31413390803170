<template>
  <CChartPie
    :datasets="defaultDatasets"
    :labels="['Free Space', 'Storage Use']"
  />
</template>

<script>
import { CChartPie } from "@coreui/vue-chartjs";

export default {
  name: "CloudStorageChartBox",
  components: { CChartPie },
  computed: {
    defaultDatasets() {
      return [
        {
          backgroundColor: ["#eeeeee", "#fc918b"],
          data: [40, 60],
        },
      ];
    },
  },
};
</script>
